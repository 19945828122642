import { AppLinksContentProvider } from "../components/contexts/AppLinksContext";
import { RecaptchaContextProvider } from "../components/contexts/RecaptchaContextProvider";
import { RegionListContentProvider } from "../components/contexts/RegionListContext";
import { SwitcherLocalesContextProvider } from "../components/contexts/SwitcherLocalesContext";
import { TranslationsContextProvider } from "../components/contexts/TranslationsContextProvider";
import { GoogleTagManger } from "../components/cookie/GoogleTagManager";
import { GenericPage } from "../components/GenericPage";
import { Layout } from "../components/Layout";
import { PostPage } from "../components/PostPage";
import { Seo } from "../components/Seo";
import { SideBarWithPostsPage } from "../components/SideBarWithPostsPage";
import { CommonPageFragment } from "../data/CommonPageFragment";
import { GenericPageAboutSettingsFragment } from "../data/GenericPageAboutSettingsFragment";
import { GenericPageLocaleFragment } from "../data/GenericPageLocaleFragment";
import { ImageFragment } from "../data/ImageFragment";
import { InternalLinkFragment } from "../data/InternalLinkFragment";
import { PostAreaItemFragment } from "../data/PostAreaItemFragment";
import { PostAreaPageLocaleFragment } from "../data/PostAreaPageLocaleFragment";
import { PostLocaleWithActiveRevisionFragment } from "../data/PostLocaleWithActiveRevisionFragment";
import { PostOrganizationItemFragment } from "../data/PostOrganizationItemFragment";
import { PostOrganizationPageLocaleFragment } from "../data/PostOrganizationPageLocaleFragment";
import { PostTileFragment } from "../data/PostTileFragment";
import { postFilter } from "../data/utilities/postFilter";
import { GenericPageType, One, OrderDirection } from "../generated/content";
import { VAKOVAKO_COUNTRY_COOKIE_KEY } from "../server/config";
import { superAdminContentQuery } from "../utilities/content";
import { getLinkableUrlFromContext } from "../utilities/getLinkableUrlFromContext";
import { getNonHiddenContentBlocks } from "../utilities/getNonHiddenContentBlocks";
import {
	handleGetServerSideProps,
	type InferDataLoaderProps,
} from "../utilities/handlers";
import { webUrl } from "../utilities/webUrl";

export type PageProps = InferDataLoaderProps<typeof getServerSideProps>;

export default function (props: PageProps) {
	const {
		general,
		header,
		footer,
		page,
		pageLocales,
		translations,
		regionList,
		currentLocaleCode,
		seo,
	} = props;

	return (
		<>
			<GoogleTagManger
				gtmId={process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID!}
				page={page}
			/>
			<Seo {...seo} />
			<SwitcherLocalesContextProvider
				pageLocales={pageLocales}
				currentLocaleCode={currentLocaleCode}
			>
				<TranslationsContextProvider data={translations}>
					<AppLinksContentProvider
						data={{
							appStoreLink: general?.localesByLocale?.appStoreLink,
							googlePlayLink: general?.localesByLocale?.googlePlayLink,
						}}
					>
						<RegionListContentProvider data={regionList}>
							<RecaptchaContextProvider>
								<Layout
									pageType={props.page.genericPage ? "generic" : undefined}
									header={header}
									footer={footer}
									isHeaderOverContent={Boolean(page.postPage)}
								>
									{page.genericPage ? (
										page.genericPage.root?.type === GenericPageType.areas &&
										page.genericPage.areaPage?.listPostArea ? (
											<SideBarWithPostsPage
												type="area"
												page={{
													id: page.genericPage.id,
													name: page.genericPage.title ?? "",
													description:
														general?.localesByLocale?.allAreasDescription,
													areas: page.genericPage.areaPage.listPostArea,
													root: {
														locales: page.genericPage.areaPage.listLocale,
														posts: page.genericPage.areaPage.listPost,
														paginatePosts: {
															pageInfo: {
																totalCount:
																	page.genericPage.areaPage.listPost.length,
															},
														},
													},
												}}
											/>
										) : page.genericPage.root?.type ===
												GenericPageType.organizations &&
											page.genericPage.organizationPage
												?.listPostOrganization ? (
											<SideBarWithPostsPage
												type="organization"
												page={{
													id: page.genericPage.id,
													name: page.genericPage.title ?? "",
													description:
														general?.localesByLocale
															?.allOrganizationsDescription,
													organizations:
														page.genericPage.organizationPage
															.listPostOrganization,
													root: {
														locales:
															page.genericPage.organizationPage.listLocale,
														posts: page.genericPage.organizationPage.listPost,
														paginatePosts: {
															pageInfo: {
																totalCount:
																	page.genericPage.organizationPage.listPost
																		.length,
															},
														},
													},
												}}
											/>
										) : (
											<GenericPage {...page.genericPage} />
										)
									) : page.areaPage ? (
										<SideBarWithPostsPage type="area" page={page.areaPage} />
									) : page.organizationPage ? (
										<SideBarWithPostsPage
											type="organization"
											page={page.organizationPage}
										/>
									) : page.postPage ? (
										<PostPage {...page.postPage} />
									) : (
										// @TODO: 404 page
										"error404"
									)}
								</Layout>
							</RecaptchaContextProvider>
						</RegionListContentProvider>
					</AppLinksContentProvider>
				</TranslationsContextProvider>
			</SwitcherLocalesContextProvider>
		</>
	);
}

// export const getStaticPaths = handleGetStaticPaths(async (context) => {
// 	const { listLinkable } = await contentQuery(process.env.CONTEMBER_CONTENT_PUBLIC_TOKEN ?? '')({
// 		listLinkable: [
// 			{
// 				filter: {
// 					// redirect: { id: { isNull: true } } // getStaticPaths cannot handle redirects
// 				},
// 			},
// 			{
// 				id: true,
// 				url: true,
// 			},
// 		],
// 	})

// 	const paths = listLinkable.map((link) => {
// 		const path = link.url.split('/').filter((part) => part !== '')

// 		let locale: string | undefined = context.defaultLocale

// 		if (context.locales?.includes(path[0])) {
// 			locale = path.shift()
// 		}

// 		return {
// 			locale,
// 			params: {
// 				path,
// 			},
// 		}
// 	})

// 	return {
// 		paths,
// 		fallback: 'blocking',
// 	}
// })

export const getServerSideProps = handleGetServerSideProps(async (context) => {
	const url = getLinkableUrlFromContext(context);

	const countryCode =
		(context.req.cookies[VAKOVAKO_COUNTRY_COOKIE_KEY] ||
			(context.req.headers["x-vercel-ip-country"] as string)) ??
		"CZ";

	const { locale } = context;

	if (!locale) {
		throw new Error("Locale not defined.");
	}

	const dataFromContember = await superAdminContentQuery({
		...CommonPageFragment(locale),
		getLinkable: [
			{
				by: { url },
			},
			{
				url: true,
				genericPage: [{}, GenericPageLocaleFragment(locale, countryCode)],
				postArea: [{}, PostAreaPageLocaleFragment(locale, countryCode)],
				postOrganization: [{ filter: { root: { isHidden: { eq: false } } } }, PostOrganizationPageLocaleFragment(locale)],
				post: [
					{ filter: { root: postFilter(countryCode, locale) } },
					PostLocaleWithActiveRevisionFragment(locale, countryCode),
				],
			},
		],
	});

	const canonicalUrl = (() => {
		const url = dataFromContember.getLinkable?.url;
		if (!url) {
			return null;
		}
		return webUrl + url;
	})();

	const genericPage = await (async () => {
		const initial = dataFromContember.getLinkable?.genericPage ?? null;

		if (!initial) {
			return null;
		}

		const pageLinks =
			dataFromContember.getLinkable?.genericPage?.root?.type ===
			GenericPageType.about
				? await superAdminContentQuery({
						getGenericPageAboutSettings: [
							{
								by: {
									unique: One.One,
								},
							},
							GenericPageAboutSettingsFragment(locale),
						],
					})
				: null;

		const areaPage =
			dataFromContember.getLinkable?.genericPage?.root?.type ===
			GenericPageType.areas
				? await superAdminContentQuery({
						listPost: [
							{
								filter: postFilter(countryCode, locale),
								orderBy: [
									{
										activeRevision: {
											publishedAt: OrderDirection.desc,
										},
									},
								],
							},
							PostTileFragment(locale),
						],
						listPostArea: [
							{
								orderBy: [
									{
										order: OrderDirection.asc,
									},
								],
								filter: {
									or: [
										{ region: { code: { isNull: true } } },
										{ region: { code: { eq: countryCode as string } } },
									],
								},
							},
							PostAreaItemFragment(locale),
						],
						listLocale: [
							{},
							{
								id: true,
								code: true,
								flagImage: [{}, ImageFragment()],
							},
						],
					})
				: null;

		const organizationPage =
			dataFromContember.getLinkable?.genericPage?.root?.type ===
			GenericPageType.organizations
				? await superAdminContentQuery({
						listPost: [
							{
								filter: {
									and: [
										postFilter(countryCode, locale),
										{
											organization: {
												id: {
													isNull: false,
												},
											},
										},
									]
								},
								orderBy: [
									{
										activeRevision: {
											publishedAt: OrderDirection.desc,
										},
									},
								],
							},
							PostTileFragment(locale),
						],
						listPostOrganization: [
							{
								orderBy: [
									{
										order: OrderDirection.asc,
									},
								],
								filter: {
									and: [
										{ isHidden: { eq: false } },
										{
											locales: {
												and: [
													{
														locale: {
															code: {
																eq: locale,
															},
														},
													},
													{
														link: {
															url: {
																isNull: false,
															},
														},
													},
												],
											},
										}
									],
								},
							},
							PostOrganizationItemFragment(locale),
						],
						listLocale: [
							{},
							{
								id: true,
								code: true,
								flagImage: [{}, ImageFragment()],
							},
						],
					})
				: null;

		areaPage?.listPostArea.unshift({
			id: initial.root?.id ?? "",
			localesByLocale: {
				id: initial.id,
				name: initial.title ?? "",
				description:
					dataFromContember.getGeneral?.localesByLocale?.allAreasDescription,
				link: initial.link,
				root: {
					icon: dataFromContember.getGeneral?.localesByLocale?.root
						?.allAreasIcon,
					paginatePosts: {
						pageInfo: {
							totalCount: areaPage.listPost.length,
						},
					},
				},
			},
		});

		organizationPage?.listPostOrganization.unshift({
			id: initial.root?.id ?? "",
			localesByLocale: {
				id: initial.id,
				name: initial.title ?? "",
				description:
					dataFromContember.getGeneral?.localesByLocale
						?.allOrganizationsDescription,
				link: initial.link,
				root: {
					icon: dataFromContember.getGeneral?.localesByLocale?.root
						?.allOrganizationsIcon,
					paginatePosts: {
						pageInfo: {
							totalCount: organizationPage.listPost.length,
						},
					},
				},
			},
		});

		return {
			...initial,
			content: getNonHiddenContentBlocks(initial.content),
			pageLinks: pageLinks?.getGenericPageAboutSettings ?? null,
			areaPage: areaPage ?? null,
			organizationPage: organizationPage ?? null,
		};
	})();

	const areaPage = await (async () => {
		const initial = dataFromContember.getLinkable?.postArea;

		if (!initial) {
			return null;
		}

		const { listPostArea, listPost, listGenericPage } =
			await superAdminContentQuery({
				listPostArea: [
					{
						orderBy: [
							{
								order: OrderDirection.asc,
							},
						],
					},
					PostAreaItemFragment(locale),
				],
				listPost: [
					{
						filter: postFilter(countryCode, locale),
						orderBy: [
							{
								activeRevision: {
									publishedAt: OrderDirection.desc,
								},
							},
						],
					},
					{
						id: true,
					},
				],
				listGenericPage: [
					{
						filter: {
							type: {
								eq: GenericPageType.areas,
							},
						},
					},
					{
						id: true,
						localesByLocale: [
							{
								by: {
									locale: {
										code: locale,
									},
								},
							},
							{
								id: true,
								link: [{}, InternalLinkFragment()],
								title: true,
							},
						],
					},
				],
			});

		if (listGenericPage) {
			listPostArea.unshift({
				id: listGenericPage[0].id ?? "",
				localesByLocale: {
					id: listGenericPage[0].localesByLocale?.id ?? "",
					name: listGenericPage[0].localesByLocale?.title ?? "",
					link: listGenericPage[0].localesByLocale?.link,
					root: {
						icon: dataFromContember.getGeneral?.localesByLocale?.root
							?.allAreasIcon,
						paginatePosts: {
							pageInfo: {
								totalCount: listPost.length,
							},
						},
					},
				},
			});
		}

		return { ...initial, areas: listPostArea };
	})();

	const organizationPage = await (async () => {
		const initial = dataFromContember.getLinkable?.postOrganization;

		if (!initial) {
			return null;
		}

		const { listPostOrganization, listPost, listGenericPage } =
			await superAdminContentQuery({
				listPostOrganization: [
					{
						orderBy: [
							{
								order: OrderDirection.asc,
							},
						],
						filter: {
							isHidden: { eq: false },
							locales: {
								and: [
									{
										locale: {
											code: {
												eq: locale,
											},
										},
									},
									{
										link: {
											url: {
												isNull: false,
											},
										},
									},
								],
							},
						},
					},
					PostOrganizationItemFragment(locale),
				],
				listPost: [
					{
						filter: postFilter(countryCode, locale),
						orderBy: [
							{
								activeRevision: {
									publishedAt: OrderDirection.desc,
								},
							},
						],
					},
					{
						id: true,
					},
				],
				listGenericPage: [
					{
						filter: {
							type: {
								eq: GenericPageType.organizations,
							},
						},
					},
					{
						id: true,
						localesByLocale: [
							{
								by: {
									locale: {
										code: locale,
									},
								},
							},
							{
								id: true,
								link: [{}, InternalLinkFragment()],
								title: true,
							},
						],
					},
				],
			});

		if (listGenericPage) {
			listPostOrganization.unshift({
				id: listGenericPage[0].id ?? "",
				localesByLocale: {
					id: listGenericPage[0].localesByLocale?.id ?? "",
					name: listGenericPage[0].localesByLocale?.title ?? "",
					link: listGenericPage[0].localesByLocale?.link,
					root: {
						icon: dataFromContember.getGeneral?.localesByLocale?.root
							?.allOrganizationsIcon,
						paginatePosts: {
							pageInfo: {
								totalCount: listPost.length,
							},
						},
					},
				},
			});
		}

		return { ...initial, organizations: listPostOrganization };
	})();

	const postPage = await (async () => {
		const initial = dataFromContember.getLinkable?.post?.root;

		const activeRevision = initial?.activeRevision;

		if (!initial || !activeRevision) {
			return null;
		}

		return {
			...activeRevision,
			...activeRevision.localesByLocale,
			root: initial,
			area: { ...initial?.area },
			content: getNonHiddenContentBlocks(
				initial.activeRevision?.localesByLocale?.content,
			),
		};
	})();

	const translations = dataFromContember.getTranslationDomain;

	const page = {
		genericPage,
		areaPage,
		organizationPage,
		postPage,
	};

	const currentPage = Object.values(page).find((page) => page !== null);

	if (!currentPage) {
		return {
			notFound: true,
		};
	}

	return {
		props: {
			general: dataFromContember.getGeneral,
			header: dataFromContember.getHeader,
			footer: dataFromContember.getFooter,
			page,
			translations,
			pageLocales: currentPage.root?.locales ?? [],
			currentLocaleCode: context.locale ?? context.defaultLocale,
			regionList: dataFromContember.listRegion,
			seo: {
				canonicalUrl,
				seo: {
					...(dataFromContember.getGeneral?.localesByLocale?.seo ?? {}),
					...Object.fromEntries(
						Object.entries(currentPage.seo ?? {}).filter(([_, value]) =>
							Boolean(value),
						),
					),
				},
			},
		},
		// revalidate: 60,
	};
});
